import React from 'react'

import './Loading.css'

export default function Loading() {
  return (
    <div className="loadingio-spinner-rolling-16jojcitm3e">
      <div className="ldio-vn75pw51by">
        <div></div>
      </div>
    </div>
  )
}
